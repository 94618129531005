<script setup>
import { onMounted } from 'vue'

onMounted(() => {
	const scriptOne = document.createElement('script')
	scriptOne.src = 'https://lidrekon.ru/slep/js/jquery.js'
	scriptOne.defer = true
	document.body.appendChild(scriptOne)

	const scriptTwo = document.createElement('script')
	scriptTwo.src = 'https://lidrekon.ru/slep/js/uhpv-full.min.js'
	scriptTwo.defer = true

	scriptOne.onload = () => {
		document.body.appendChild(scriptTwo)
	}
})
</script>

<template>
	<img
		id="specialButton"
		style="cursor: pointer"
		src="https://lidrekon.ru/images/special.png"
		alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
		title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
	/>
</template>

<style lang="scss" scoped>
img {
	width: 40px;
}
</style>
